<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách vị trí công việc hợp lệ </h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :select-options="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          max-height="100%"
          style-class="table-listwork"
        >
          @on-selected-rows-change="selectionChanged"
          >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'score'">
              <span style="font-weight: bold; color: blue;">tiennguyen</span>
            </span>
          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} vị trí công việc</p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách vị trí công việc không hợp lệ</h4>
        </div>
        <div class="listwork__head__02__right">

          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="table-listwork"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->

            <span
              v-if="props.column.field == 'code' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Code'||!props.row.code,dataErr:!props.row.code&&props.row.messErr[0].location === 'Code'}"
            >

              {{ props.row.code }}
            </span>

            <span v-if="props.column.field == 'code' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.code, 'code', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Code'"
              >{{ props.row.code }}</span>
            </span>
            <span
              v-if="props.column.field == 'name' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Name'||!props.row.name,dataErr:!props.row.name,}"
            >

              {{ props.row.name }}
            </span>

            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>
            <span v-if="props.column.field == 'positionGroup' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'PositionGroup'"
                v-model="props.row.positionGroup"
                :reduce="title => title.name"
                label="name"
                :options="valueSelect"
                :placeholder="'Lựa chọn nhóm công việc'"
                @input="changeCellvalue(props.row.positionGroup, 'positionGroup', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'PositionGroup'"
              >{{ props.row.positionGroup }}</span>
            </span>
            <span v-if="props.column.field == 'orStruc' && editvalueErr === true ">
              <organization-select
                v-if="props.row.messErr[0].location === 'OrStruc'"
                v-model="props.row.idOrStru"
                class="border-organization"
                @input="changeCellvalueOr(props.row.orStruc, 'orStruc', props.row)"
                @checkNameText="checkNameText"
              />
              <span
                v-if="props.row.messErr[0].location !== 'OrStruc'"
              >{{ props.row.orStruc }}</span>
            </span>

            <span v-if="props.column.field == 'description'">
              <span>{{ props.row.description }}</span>
            </span>
            <span v-if="props.column.field == 'requirement'">
              <span>{{ props.row.requirement }}</span>
            </span>
            <span
              v-if="props.column.field == 'positionGroup' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'PositionGroup'||!props.row.positionGroup,dataErr:!props.row.positionGroup,}"
            >
              {{ props.row.positionGroup }}
            </span>
            <span
              v-if="props.column.field == 'orStruc' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'OrStruc'||!props.row.orStruc,dataErr:!props.row.orStruc,}"
            >
              {{ props.row.orStruc }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }}  vị trí công việc</p>
      </div>

      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addPositionJob()"
          >
            Thêm vị trí công việc
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import OrganizationSelect from '@/views/management-user/users/pages/components/OrganizationSelect.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'

export default {
  components: {
    VueGoodTable,
    BButton,
    VSelect,
    OrganizationSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      model: {},
      valueSelectone: '',
      valueSelect: [
        { name: 'Khác' },
        {
          name: 'Nhà quản lý',
        },
        {
          name: 'Chuyên môn kỹ thuật bậc cao',
        },
        {
          name: 'Chuyên môn kỹ thuật bậc trung',
        },
      ],
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Mã vị trí công việc',
          field: 'code',
        },
        {
          label: 'Vị trí công việc',
          field: 'name',
        },
        {
          label: 'Mô tả công việc',
          field: 'description',
        },
        {
          label: 'Yêu cầu công việc',
          field: 'requirement',
        },
        {
          label: 'Nhóm công việc',
          field: 'positionGroup',
        },
        {
          label: 'CƠ CẤU TỔ CHỨC',
          field: 'orStruc',
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'Mã vị trí công việc',
          field: 'code',
        },
        {
          label: 'Vị trí công việc',
          field: 'name',
        },
        {
          label: 'Mô tả công việc',
          field: 'description',
        },
        {
          label: 'Yêu cầu công việc',
          field: 'requirement',
        },
        {
          label: 'Nhóm công việc',
          field: 'positionGroup',
        },
        {
          label: 'CƠ CẤU TỔ CHỨC',
          field: 'orStruc',
          tdClass: 'style-code123',
          width: '350px',

        },
      ],
      dataCheck: [],
      nameOrStru: '',
    }
  },
  computed: {
    ...mapState('positionJob', ['validWork', 'validNoWork']),
  },
  created() {
    this.dataInvalid = this.validWork
    this.validNoWork.forEach(e => {
      e.idOrStru = null
    })
    this.dataNoInvalid = this.validNoWork
  },
  methods: {
    ...mapActions('positionJob', ['getApiExcel']),
    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },

    // thay đổi dữ liệu trên bảng
    changeCellvalueOr(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = this.nameOrStru
    },
    // nút check
    async CheckdataNoInvalid() {
      this.model = {
        listExcel: this.dataInvalid.concat(this.dataNoInvalid),
        isValidate: true,
      }
      const valuedata = await this.getApiExcel(this.model)
      this.dataInvalid = valuedata.data.filter(x => x.isSuccess === true)
      const dataTemp = valuedata.data.filter(x => x.isSuccess === false)
      if (dataTemp.length > 0) {
        this.dataNoInvalid = dataTemp
      } else {
        this.dataNoInvalid = []
      }
    },

    checkNameText(val) {
      this.nameOrStru = val.name
    },

    // nút hủy bỏ
    close() {
      this.$router.push({ name: 'position-job' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addPositionJob() {
      if (this.dataInvalid && this.dataInvalid.length !== 0) {
        const model = {
          listExcel: this.dataInvalid,
          isValidate: false,
        }
        const res = await this.getApiExcel(model)
        if (res.oke) {
          this.dataInvalid = []
          if (this.dataNoInvalid && this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'position-job' })
          }
          this.$root.$bvToast.toast('Thêm thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast('Thêm không thành công!', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } else {
        this.$root.$bvToast.toast('Không có dữ liệu để thêm!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
